#app-footer {
  position: relative;
  /* side nav is 850 */
  z-index: 851;
}

#page-footer {
  background-color: #232f3e;
  display: flex;
  justify-content: center;
  padding: 8px;
}

.footer-link {
  color: #d5dbdb;
}

.footer-link a {
  color: #d5dbdb;
}

.link-button {
  color: #d5dbdb;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: underline;
}

.z2 {
  z-index: 2;
}