.text-center { 
  height: 200px;
  line-height: 200px !important;
  text-align: center;
}

.v-center {
 display: inline-block;
 
 vertical-align: middle;
 
 line-height: normal;
}

.no-padding {
  padding: 0px !important;
}

.no-padding-t {
  padding-top: 0px !important;
}

.no-padding-b {
  padding-bottom: 0px !important;
}

.margin-b-20 {
  margin-bottom: 20px !important;
}

/*
can't style button text as blue using built-in component and props, some options:
1. use the default button color (black)
2. use a Link component
3. keep this custom css class
*/
.button-link {
  color: #0073bb !important;
}

.alert-no-icon > .awsui-alert > .awsui-alert-icon {
  display: none !important;
}

.alert-no-icon > div > svg {
  display: none !important;
}

.alert-file-upload-padding > div {
  padding: 5px 8px !important;
}

#alertFileUpload > div > .awsui-alert-dismiss {
  padding: 12px !important;
}