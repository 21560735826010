#app-header {
  position: sticky;
  top: 0;
}

#page-header {
  background-color: #232f3e;
  color: #ffffff;
  display: flex;
}

.header-logo {
  background-image: url("./aws_logo.svg");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  height: 25px;
  width: 50px;
  color: transparent;
}

.header-logo-item {
  align-self: center;
  padding-top: 8px;
  height: 32px;
  margin-left: 60px;
}

.header-nav {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.header-nav-item {
  margin-right: 10px;
  margin-left: 10px;
  line-height: 40px;
  display: block;
}
/* Used to vertically align icon with text. Override the default Polaris vertical-align
for the icon because it's used within our custom built header navigation bar */
#header-contact-support-icon {
  vertical-align: sub !important;
}

.z2 {
  z-index: 2;
}